import {
    Box,
    Button,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { deleteRepos } from "../../helpers/repos";
import { FlashbarPublisher } from "../FlashbarPublisher";

export const DeleteRepoModal = ({ isVisible, setIsVisible, selectedRepos, refreshRepoList }) => {

    async function deleteRepoUrl() {
        setIsVisible(false);
        try {
            const reposToDelete = selectedRepos.map(repo => ({ url: repo.url, managerAlias: repo.managerAlias }));
            await deleteRepos(reposToDelete);
            FlashbarPublisher.setItems([{
                id: "delete_repo_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `Repositor${selectedRepos.length === 1 ? 'y' : 'ies'} deleted successfully.`
            }]);
        } catch (error) {
            FlashbarPublisher.setItems([{
                id: "delete_repo_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `There was an error deleting the selected repositor${selectedRepos.length === 1 ? 'y' : 'ies'}.`
            }]);
        }
        await refreshRepoList();
    };

    return (
        <Modal
            data-testid={"delete-repo-modal"}
            onDismiss={() => {
                setIsVisible(false)
            }}
            visible={isVisible}
            header={`Delete repositor${selectedRepos.length === 1 ? 'y' : 'ies'}`}
            footer={
                <Box float="right">
                    <SpaceBetween direction={"horizontal"} size={"xs"}>
                        <Button
                            data-testid={"cancel-delete-repo-modal-button"}
                            variant="link"
                            onClick={() => {
                                setIsVisible(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"delete-repo-modal-button"}
                            variant="primary"
                            onClick={async () => { await deleteRepoUrl() }}
                        >
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            Permanently delete {selectedRepos.length} repositor{selectedRepos.length === 1 ? 'y' : 'ies'}? Developers will not be able to push code to {selectedRepos.length === 1 ? 'this repository' : 'these repositories'} anymore.
        </Modal>
    );
};
