import { LocalStorage } from "../../core/storage";
import { DELEGATIONS } from "../../core/user/user";

export const getDelegationAlias = async () => {
    const delegations = LocalStorage.get(DELEGATIONS);
    const aliasDelegatedToUser = [];
    delegations?.forEach(delegation => {
        aliasDelegatedToUser.push(delegation['Manager']['Alias'])
    })
    console.log('900 alias of managers Delegated To User: ', aliasDelegatedToUser);
    return aliasDelegatedToUser;   
}