import { useEffect, useState } from "react";

import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import { AddRepoModal } from './AddRepoModal';
import { DeleteRepoModal } from './DeleteRepoModal';
import { PhoneToolHyperlink } from "../phone-tool-hyperlink";
import { User } from '../../core/user';
import { searchRepos } from '../../helpers/repos';
import { getDelegationsFromRoles } from '../../helpers/delegation';
import { isAdmin } from '../../helpers/isAdmin';
import { isAvpFlagOn } from "../../helpers/isAvpFlagOn";
import { LocalStorage } from "../../core/storage";
import { DELEGATION_CHAIN, DELEGATION_ALIAS } from "../../core/user/user";

// Check to see if the given search text is valid. This means it is either 
// missing, empty or has more than 3 valid characters 
const validateSearchText = (searchText) => {
    return (!searchText) || searchText.replace(/\W/, '').length >= 3;
};

// From https://stackoverflow.com/questions/3177836
function timeAgo(input) {
    const date = (input instanceof Date) ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat('en');
    const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
            const delta = secondsElapsed / ranges[key];
            return formatter.format(Math.round(delta), key);
        }
    }

    return "Just now";
}

// Get the rendered type for the repo based on the current user and the owner
// of the repo. The rules are as follows.
// repo.repoType == "OpenSource" => "Open-Source"
// repo.managerAlias == currUser.username => "Org-Specific" though "Mine" is better
// currUser.delegations.has(repo.managerAlias) => "Delegated"
// repo.managerAlias == currUser.chain[-1] => "Org-Specific"
// currUser.delegations.managerChain.has(repo.managerAlias ) => "Delegated, Inherited"
// otherwise => "Inherited"
export const renderedRepoType = (repo) => {
    const avpFlagOn = isAvpFlagOn();

    if (repo?.repoType === "OpenSource") {
        return "Open-Source";
    }

    const user = new User();
    if (!repo) {
        return "";
    }

    if (repo.managerAlias === user.userId) {
        return "Org-Specific";
    }

    if (avpFlagOn){
        if(
            (new Set(LocalStorage.get(DELEGATION_ALIAS)))
            .has(repo.managerAlias)
        ){
            return "Delegated";
        }
    } else {
        if (
            (new Set(getDelegationsFromRoles().map(d => d.managerAlias)))
                .has(repo.managerAlias)
        ) {
            return "Delegated";
        }
    }

    if (user.chain?.at(-1) === repo.managerAlias) {
        return "Org-Specific";
    }

    if (avpFlagOn){
        if(
            (new Set(LocalStorage.get(DELEGATION_CHAIN)))
            .has(repo.managerAlias)
        ){
            return "Delegated, Inherited";
        }
    } else {
        if (
            (new Set(getDelegationsFromRoles().flatMap(d => d.managerChain)))
                .has(repo.managerAlias)
        ) {
            return "Delegated, Inherited";
        }
    }



    return "Inherited";
}

// Component to display a table of allowed repos and allow for searching and
// pagination. The parameters taken by this component are
// - adminView: boolean => Indicates whether or not the administrative 
//            functionality of this component is enabled
export const RepoSearchTable = ({ adminView, managers, search, setSearch, user }) => {


    const [displayedSearchText, setDisplayedSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchesByPage, setSearchesByPage] = useState([search]);
    const [pageIndex, setPageIndex] = useState(0);
    const [foundFinalPage, setFoundFinalPage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [countPerPage, setCountPerPage] = useState([]);
    const [selectedRepos, setSelectedRepos] = useState([]);
    const [addModalIsVisible, setAddModalIsVisible] = useState(false);
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    // call this when there's a change to the search string. This invalidates
    // all the existing results and pagination info
    const onNewSearch = (searchText) => {
        const newSearch = { searchText, managers };
        setSearchesByPage([newSearch]);
        setSearch(newSearch);
        setSelectedRepos([]);
        setPageIndex(0);
        setFoundFinalPage(false);
        setSearchResults([]);
        setCountPerPage([]);
    };

    // Search text needs to be either empty or more than 2 characters long
    const validSearchText = validateSearchText(search.searchText);

    const updateSearchResults = async () => {
        if (!validSearchText) {
            return;
        }
        setLoading(true);
        const resp = await searchRepos(search);
        setLoading(false);
        setSearchResults(resp.repos);

        let newCounts = [...countPerPage];
        if (resp.repos) {
            if (countPerPage.length <= pageIndex) {
                newCounts.push(resp.repos.length);
            } else {
                newCounts[pageIndex] = resp.repos.length;
            }
        }

        setCountPerPage(newCounts);

        if (resp.nextSearch) {
            const newPages = [...searchesByPage];
            if (newPages.length <= pageIndex + 1) {
                newPages.push(resp.nextSearch);
                setSearchesByPage(newPages);
            }
        } else {
            setFoundFinalPage(true);
        }
    };

    useEffect(updateSearchResults, [search]);

    let emptyListMessage;

    if (loading) {
        emptyListMessage = (<i>Searching...</i>);
    } else if (!validSearchText) {
        emptyListMessage = "Enter at least 3 characters to start searching";
    } else {
        emptyListMessage = (<b>No Repositories Found 😓</b>);
    }

    const avpFlagOn = isAvpFlagOn();
    let effectiveManagerList;
    if (avpFlagOn){
        effectiveManagerList = LocalStorage.get(DELEGATION_ALIAS);
    } else {
        effectiveManagerList = getDelegationsFromRoles()
        .map(del => del.managerAlias);
    }


    if (isAdmin()) {
        effectiveManagerList.push(user.userId);
    }

    const isManageableRepo = (repo) => {
        return effectiveManagerList.includes(repo.managerAlias);
    }

    return (<>

        <AddRepoModal
            isVisible={addModalIsVisible}
            setIsVisible={(isVisible) => setAddModalIsVisible(isVisible)}
            refreshRepoList={updateSearchResults}
        />
        <DeleteRepoModal
            isVisible={deleteModalIsVisible}
            setIsVisible={(isVisible) => setDeleteModalIsVisible(isVisible)}
            selectedRepos={selectedRepos}
            refreshRepoList={updateSearchResults}
        />

        <Table
            selectionType={adminView ? 'multi' : undefined}
            onSelectionChange={({ detail }) =>
                setSelectedRepos(detail.selectedItems)
            }
            selectedItems={selectedRepos}
            columnDefinitions={[
                {
                    id: "url",
                    header: "Url",
                    cell: item => <Link href={item.url} target='_blank'>{item.url}</Link>,
                    isRowHeader: true
                },
                {
                    id: "managerAlias",
                    header: "Manager",
                    cell: item => {
                        if (item.repoType === 'OpenSource') {
                            return '';
                        } else {
                            return <PhoneToolHyperlink alias={item.managerAlias} />;
                        }
                    }
                },
                {
                    id: "creator",
                    header: "Creator",
                    cell: item => {
                        if (item.repoType === 'OpenSource') {
                            return '';
                        } else {
                            return <PhoneToolHyperlink alias={item.creator} />;
                        }
                    }
                },
                {
                    id: "repoType",
                    header: "Type",
                    cell: renderedRepoType
                },
                {
                    id: "dateAdded",
                    header: "Added",
                    cell: item => timeAgo(item.dateAdded)
                }
            ]}
            columnDisplay={[
                { id: "url", visible: true },
                { id: "managerAlias", visible: true },
                { id: "creator", visible: true },
                { id: "repoType", visible: true },
                { id: "dateAdded", visible: true }
            ]}
            items={loading ? [] : searchResults}
            isItemDisabled={repo => !isManageableRepo(repo)}
            loadingText="Loading Repositories"
            trackBy={(repo) => `${repo.url}|${repo.managerAlias}`}
            empty={
                emptyListMessage
            }
            filter={
                <TextFilter
                    filteringPlaceholder="Find Repositories"
                    filteringText={displayedSearchText}
                    onChange={({ detail }) => setDisplayedSearchText(detail.filteringText)}
                    onDelayedChange={({ detail }) => onNewSearch(detail.filteringText)}
                />
            }
            header={
                < Header
                    counter={
                        selectedRepos.length
                            ? `(${selectedRepos.length} selected)`
                            : ''
                    }
                    actions={
                        !adminView ? undefined :
                            <SpaceBetween direction={"horizontal"} size="xs">
                                <Button
                                    data-testid={"delete-repo-button"}
                                    onClick={() => { setDeleteModalIsVisible(true) }}
                                    disabled={selectedRepos.length === 0}
                                >
                                    Delete
                                </Button>
                                <Button
                                    data-testid={"add-repo-button"}
                                    variant="primary"
                                    onClick={() => { setAddModalIsVisible(true) }}
                                    disabled={true}
                                >
                                    Add repository
                                </Button>
                            </SpaceBetween>
                    }
                >
                    Repository Allow List
                </Header >
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="refresh" variant="icon" onClick={() => {
                            setSelectedRepos([]);
                            updateSearchResults();
                        }} />
                        < Pagination
                            disabled={loading}
                            currentPageIndex={pageIndex + 1}
                            pagesCount={searchesByPage.length}
                            openEnd={!foundFinalPage}
                            onChange={({ detail }) => {
                                console.log("Page Changing to " + detail.currentPageIndex);
                                const newPageIndex = detail.currentPageIndex - 1;
                                if (newPageIndex !== pageIndex) {
                                    setPageIndex(newPageIndex)
                                    setSearch(searchesByPage[newPageIndex])
                                }
                            }}
                        />
                    </SpaceBetween>
                </Box>
            }
        />
    </>);
}