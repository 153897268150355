// Polaris
import {
    Box,
    Button,
    Header,
    Icon,
    Link,
    SpaceBetween,
    Table,
    TextFilter,
    Pagination
} from '@amzn/awsui-components-react';
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useEffect, useState } from 'react';
import { User } from '../../core/user';
import { getRequests, sendApprovalRequests, RepoRequstStatus } from '../../helpers/repos/repoRequests'
import { FlashbarPublisher } from '../FlashbarPublisher';
import {RejectRepoModal} from "./RejectRepoModal";

export const RepositoryRequestTable = ({isAdmin, setSearch, search, managers }) => {

    const getMatchesCountText = (count) => {
        return count === 1 ? `1 match` : `${count} matches`;
    }

    const makeFlashbarListContentFromRequests = (message, requests) => {
        return <>
            {message}
            <ul>
                {
                    requests.map((item, index) => {
                        return <li>{item.url}</li>
                    })
                }
            </ul>
        </>
    }

    const approveRepoRequests = async () => {
        const failedRequests = await sendApprovalRequests(selectedRequests);
        if (failedRequests.length > 0) {
            FlashbarPublisher.setItems([{
                id: "add_approval_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: makeFlashbarListContentFromRequests(`Failed to approve ${failedRequests.length} request${failedRequests.length > 1 ? 's' : ''}. Please try again.`, failedRequests)
            }]);
        } else {
            FlashbarPublisher.setItems([{
                id: "add_approval_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `Repository request${selectedRequests.length > 1 ? 's have' : ' has'} been approved and added to the repository allow list. The repositor${selectedRequests.length > 1 ? 'ies are' : 'y is'} now available for developers to push code to.`
            }]);
        }
        try {
            await getRepoRequests();
            const newSearchText = ""; // change the search state so the updateSearchResults function in repositories-ui/RepoSearchTable.jsx will be triggered as effect
            //Currently the refresh functionality is build in to changing the search results. 
            //Refresh button on UI also makes a call to updateSearchResults to achieve refresh functionality
            //After discussion during standup, team was okay with going with the approach of temporarily modifying the search text to invoke a refresh
            const newSearch = { newSearchText, managers };
            setSearch(newSearch);
            setSearch(search); // preserve the original search text
        } catch (e) {
            // Display refresh flash bar error
            FlashbarPublisher.pushItem([{
                id: "add_refresh_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Failed to refresh request table."
            }]);
        }
    }

    const getRepoRequests = async () => {
        setSelectedRequests([])
        setLoading(true)

        // Request to backend for repo requests
        try {
            let response = await getRequests();
            setRepoRequests(response.requests);
        }
        catch (err) {
            // TODO: Display flash bar error (after Kage's refactor)
        }

        setLoading(false)
    }

    const getRenderedStatus = (status) => {
        if (status == RepoRequstStatus.PENDING) {
            return <p style={{ color: 'gray' }}><Icon name={"status-pending"} /> {status}</p>
        } else if (status == RepoRequstStatus.APPROVED) {
            return <p style={{ color: '#6baf35' }}><Icon name={"status-positive"} variant='success'/> {status}</p>
        } else if (status == RepoRequstStatus.REJECTED) {
            return <p style={{ color: '#fe5e64' }}><Icon name={"status-negative"} variant='error'/> {status}</p>
        }
    }

    const sortingFunction = (a , b) => {
        // Pending statuses should go first
        if (a.status == RepoRequstStatus.PENDING && b.status != RepoRequstStatus.PENDING) {
            return 1;
        } else if (a.status != RepoRequstStatus.PENDING && b.status == RepoRequstStatus.PENDING) {
            return -1;
        }

        // Sort by timestamp if both are pending and last modified at if both are not pending
        if (a.status == RepoRequstStatus.PENDING && b.status == RepoRequstStatus.PENDING) {
            // Within 'PENDING', keep actionable requests on top
            if (user.userId == a.requester && user.userId != b.requester) {
                return -1;
            } else if (user.userId != a.requester && user.userId == b.requester) {
                return 1;
            }

            if (new Date(a.timestamp) < new Date(b.timestamp)) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (new Date(a.lastModifiedAt) < new Date(b.lastModifiedAt)) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    // Get user for disabling checkboxes
    const user = new User();

    // State
    const[rejectRepoModalIsVisible, setRejectRepoModalIsVisible] = useState(false);
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [loading, setLoading] = useState(false)
    const [repoRequests, setRepoRequests] = useState([])
    const sortDescending = true

    // List of table ids, also used for defining which attribute on item to sort and filter
    const url = "url";
    const reviewer = "reviewer";
    const requester = "requester";
    const timestamp = "timestamp";
    const rejectionReason = "rejectionReason";
    const lastUpdatedAt = "lastUpdatedAt";
    const status = "status";

    const statusSortingColumn = {
        id: status,
        header: "Status",
        cell: item => getRenderedStatus(item.status),
        sortingComparator: sortingFunction
    };

    // Table collection
    const { items, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        repoRequests,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [url]
            },
            sorting: {
                defaultState: {
                    sortingColumn: statusSortingColumn,
                    isDescending: sortDescending
                }
            },
            selection: {}
        }
    );

    useEffect(() => {
        getRepoRequests();
    }, []);

    return  (<>

        <RejectRepoModal
            isVisible={rejectRepoModalIsVisible}
            setIsVisible={(isVisible) => setRejectRepoModalIsVisible(isVisible)}
            refreshRepoRequestList={getRepoRequests}
            makeFlashBar = {makeFlashbarListContentFromRequests}
            selectedRequests = {selectedRequests}
        />

        <Table
            {...collectionProps}
            header={
                <Header
                    counter={`(${repoRequests.length})`}
                    actions={
                        isAdmin ?
                            <SpaceBetween direction='horizontal' size='s'>
                                <Button
                                    data-testid={'request-action-button'}
                                    variant='normal'
                                    disabled={selectedRequests.length == 0}
                                    onClick={() => {setRejectRepoModalIsVisible(true)}}
                                >
                                    Reject
                                </Button>
                                <Button data-testid={'request-action-button'} variant='primary' disabled={true} onClick={approveRepoRequests}>Approve</Button>
                            </SpaceBetween>
                            :
                            <></>
                    }
                >
                    Repository Requests
                </Header>
            }
            selectionType={isAdmin ? 'multi' : undefined}
            onSelectionChange={({ detail }) => {
                setSelectedRequests(detail.selectedItems);
            }}
            isItemDisabled={(item) =>
                item.status !== RepoRequstStatus.PENDING
            }
            selectedItems={selectedRequests}
            ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
            }}
            items={items}
            loading={loading}
            loadingText='Loading Repository Requests'
            columnDefinitions={
                [
                    {
                        id: url,
                        header: "Url",
                        cell: item => <Link external href={item.url}>{item.url}</Link>,
                        isRowHeader: true,
                        sortingField: url,
                    },
                    {
                        id: reviewer,
                        header: "Reviewer",
                        cell: item => item.approver == "_" ? "N/A" : item.approver,
                        sortingField: reviewer
                    },
                    {
                        id: "managerAlias",
                        header: "Manager",
                        cell: item => item.approvedFor,
                        sortingField: "approvedFor"
                    },
                    {
                        id: requester,
                        header: "Requester",
                        cell: item => item.requester,
                        sortingField: requester
                    },
                    {
                        id: timestamp,
                        header: "Requested Date",
                        cell: item => new Date(item.timestamp).toLocaleDateString(),
                        sortingField: timestamp
                    },
                    {
                        id: "requestReason",
                        header: "Request Reason",
                        cell: item => <span title={`${item.reason}`}>{item.reason}</span>,
                        maxWidth: 200,
                        sortingField: "reason"
                    },
                    {
                        id: rejectionReason,
                        header: "Reject Reason",
                        cell: item => <span title={`${item.rejectionReason}`}>{item.rejectionReason}</span>,
                        maxWidth: 200,
                        sortingField: rejectionReason
                    },
                    statusSortingColumn,
                    {
                        id: lastUpdatedAt,
                        header: "Last updated",
                        cell: item => item.lastUpdatedAt ? new Date(item.lastUpdatedAt).toLocaleDateString() : "N/A",
                        sortingField: lastUpdatedAt
                    }
                ]
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Find Repository Requests"
                />
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="refresh" variant="icon" disabled={loading} onClick={getRepoRequests} />
                        < Pagination
                            {...paginationProps}
                            disabled={loading}
                            openEnd={false}

                        />
                    </SpaceBetween>
                </Box>
            }
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <b>No requests</b>
                </Box>
            }
        />
    </>);
}