import getCurrentUserDetails from './getCurrentUserDetails';
import { LocalStorage } from "../core/storage";
import { DELEGATION_ALIAS } from "../core/user/user";

const getManagerChainByAlias = async (client, alias) => {
    try{
        const userDetails = await getCurrentUserDetails(client, alias);
        const chain = [];
        chain.push(...userDetails['chain']);
        chain.push(alias);//adding this manager self to chain.
        console.log('100 manager is: ', alias);
        console.log('100 chain for manager is: ', chain)
        return chain;
    } catch (e) {
        console.warn(`Error getting manager chain by user alias - `, e);
    }
}

export const getAllManagerChain = async (client) => {
    const delegationAlias = LocalStorage.get(DELEGATION_ALIAS);
    let allChain = await Promise.all(delegationAlias.map(alias => getManagerChainByAlias(client, alias)));
    console.log('100 all chain for manager is: ', allChain);
   // let flatChain = new Set(allChain.flatMap(arr => arr));
    let flatChain = allChain.flatMap(arr => arr);
    console.log('100 flat all chain: ', flatChain);
    return flatChain;   
}